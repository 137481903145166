export const truncate = function (str, length = 100) {
  if (!str) return <span></span>;
  if (!length) return <span>{str}</span>;
  let string = str;
  string = str?.length > length + 3 ? str?.substring(0, length) + "..." : str;
  return (
    <span
      onClick={() => {
        string = str;
      }}
    >
      {string}
    </span>
  );
};
